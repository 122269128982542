<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
        <!-- CLASSIFICATION DES VOYAGES ET/OU EXCURSIONS -->
        <div>
          <b-card>
            <b-card-header class="d-flex justify-content-between">
              <h3>CLASSIFICATION DES VOYAGES ET/OU EXCURSIONS</h3>
            </b-card-header>
            <hr>
            <b-card-body>
              <!-- voyage dans le dernier trimestre -->
              <b-row class="mt-1">
                <b-col md="12">
                  <div>
                    Pouvez-vous nous dire si vous ou toute autre personne de votre foyer avez réalisé un voyage ou une excursion au cours du dernier trimestre ?    
                  </div>
                </b-col>
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-radio-group
                    v-model="infos.voyagesDernierTrimestre"
                    :options="optionsOuiNon"
                    class="demo-inline-spacing mb-1 ml-1"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                  />
                  <small class="text-danger ml-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-row>
              <!-- raison du non voyage -->
              <b-row class="mt-1" v-if="infos.voyagesDernierTrimestre == 'Non'">
                <b-col md="12"><div>
                  Raison pour laquelle vous n'avez pas réalisé de voyage au cours du dernier trimestre :
                </div></b-col>
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-radio-group
                    v-model="infos.raisonNonVoyage"
                    :options="optionsNonVoyage"
                    class="demo-inline-spacing mb-1 ml-1"
                    value-field="value"
                    text-field="text"
                    disabled-field="disabled"
                  />
                  <small class="text-danger ml-1">{{ errors[0] }}</small>
                </validation-provider>
                <b-col
                  v-if="infos.raisonNonVoyage == 'Autres'"
                  class=""
                  md="10"
                >
                  <b-form-group
                    label-cols="5"
                    label-cols-lg="3"
                    label="Veuillez la préciser"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input v-model="raisonNonVoyageAutre" type="text"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-else class="mt-1" md="6" />
              </b-row>
              <hr>
              <!-- précisions -->
              <div v-if="infos.voyagesDernierTrimestre == 'Oui'">
                <div>Précisez si vous avez effectué : </div>
                <b-row class="mt-1">  
                  <b-col md="12">
                    <div>Des voyages au Bénin </div>
                  </b-col>
                  <b-col md="4">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-radio-group
                        v-model="infos.voyageBenin.statut"
                        :options="optionsOuiNon"
                        class="demo-inline-spacing mb-1 ml-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                      <small class="text-danger ml-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="infos.voyageBenin.statut == 'Oui'"
                    class="mt-1"
                    md="8"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Le nombre"
                      class=""
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model.number="infos.voyageBenin.nombre"
                          type="number" min="1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-else class="mt-1" md="6" />
                </b-row>
                <b-row class="mt-1">  
                  <b-col md="12">
                    <div>Des voyages à l'étranger </div>
                  </b-col>
                  <b-col md="4">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-radio-group
                        v-model="infos.voyageEtranger.statut"
                        :options="optionsOuiNon"
                        class="demo-inline-spacing mb-1 ml-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                      <small class="text-danger ml-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="infos.voyageEtranger.statut == 'Oui'"
                    class="mt-1"
                    md="8"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Le nombre"
                      class=""
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model.number="infos.voyageEtranger.nombre"
                          type="number" min="1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-else class="mt-1" md="6" />
                </b-row>
                <b-row class="mt-1">  
                  <b-col md="12">
                    <div>Des excursions au Bénin </div>
                  </b-col>
                  <b-col md="4">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-radio-group
                        v-model="infos.excusionBenin.statut"
                        :options="optionsOuiNon"
                        class="demo-inline-spacing mb-1 ml-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                      <small class="text-danger ml-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="infos.excusionBenin.statut == 'Oui'"
                    class="mt-1"
                    md="8"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Le nombre"
                      class=""
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model.number="infos.excusionBenin.nombre"
                          type="number" min="1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-else class="mt-1" md="6" />
                </b-row>
                <b-row class="mt-1">  
                  <b-col md="12">
                    <div>Des excursions à l'étranger </div>
                  </b-col>
                  <b-col md="4">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-radio-group
                        v-model="infos.excusionEtranger.statut"
                        :options="optionsOuiNon"
                        class="demo-inline-spacing mb-1 ml-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                      <small class="text-danger ml-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="infos.excusionEtranger.statut == 'Oui'"
                    class="mt-1"
                    md="8"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Le nombre"
                      class=""
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model.number="infos.excusionEtranger.nombre"
                          type="number" min="1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-else class="mt-1" md="6" />
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>

import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      email,
      required,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      infos: {
        voyagesDernierTrimestre: null,
        raisonNonVoyage: null,
        voyageBenin: {
          statut: null,
          nombre: null,
        },
        voyageEtranger: {
          statut: null,
          nombre: null,
        },
        excusionBenin: {
          statut: null,
          nombre: null,
        },
        excusionEtranger: {
          statut: null,
          nombre: null,
        },
      },
      raisonNonVoyageAutre: null,


      optionsOuiNon: ["Oui", "Non"],
      optionsNonVoyage: [
        "Pas intéressé", 
        "Tarifs élevé de l'hébergement", 
        "Tarifs élevé des transports en commun",
        "Insuffisance de transports en commun",
        "Autres",
      ],
    }
  },
  computed: {
    ...mapState('params', {
      listPays: 'listPays',
      listMois: 'listMois',
      listAnnees: 'listAnnees',
    }),
  },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.inputData) {
      this.infos = this.inputData
    } else if (this.$route.params.data) {
      this.infos = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },
  methods: {
    ...mapActions('params', {
      fetchAnnees: 'fetchAnnees',
    }),
    onCancel() {
      this.$emit('onCancel', {})
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          if (this.inputData) {
            this.$emit('onFormSuccess', this.infos)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.infos },
            })
          }
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
