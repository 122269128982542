<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
        <!-- RENSEIGNEMENTS GENERAUX  -->
        <div>
          <b-card>
            <b-card-header class="d-flex justify-content-between">
              <h3>IDENTIFICATION</h3>
            </b-card-header>
            <hr>
            <b-card-body>

              <!-- nom -->
              <b-form-group
                class="mt-2"
                label-cols-md="3"
                label="Nom"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model="infos.lastname"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- prénom -->
              <b-form-group
                class="mt-2"
                label-cols-md="3"
                label="Nom"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model="infos.firstname"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition], //
  data() {
    return {
      email,
      required,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      infos: {
        firstname: '',
        lastname: '',
      },
    }
  },
  computed: {
    // ...mapState('params', {
    //   listPays: 'listPays',
    //   listMois: 'listMois',
    //   listAnnees: 'listAnnees',
    // }),
  },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {

    if (this.inputData) {
      this.infos = this.inputData
    } else if (this.$route.params.data) {
      this.infos = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },
  methods: {
    // ...mapActions('params', {
    //   fetchAnnees: 'fetchAnnees',
    // }),
    onCancel() {
      this.$emit('onCancel', {})
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          if (this.inputData) {
            this.$emit('onFormSuccess', this.infos)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.infos },
            })
          }

        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
