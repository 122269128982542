<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
        <div>
          <b-card>
            <b-card-header class="d-flex justify-content-between">
              <h3>EXCURSIONS AU BENIN</h3>
            </b-card-header>
            <hr />
            <b-card-body>
              <div class="table-responsive text-center">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" >301</th>
                      <th scope="col" >N° de série du Excursion</th>
                      <th scope="col" >Excursion(1)</th>
                      <th scope="col" >Excursion(2)</th>
                      <th scope="col" >Excursion(3)</th>
                      <th scope="col" >Excursion(4)</th>
                      <th scope="col" >Excursion(5)</th>
                      <th scope="col" >Excursion(6)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- nbre personnes Excursions -->
                    <tr>
                      <th scope="row">302</th>
                      <td>Nb de personnes ayant effectué ce Excursion</td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].nombrePersonne"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].nombrePersonne"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].nombrePersonne"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].nombrePersonne"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].nombrePersonne"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].nombrePersonne"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <!-- motif Excursion -->
                    <tr>
                      <th scope="row"></th>
                      <td>
                        Motif principal du Excursion 
                      </td>

                      <td>
                        <b-row class="" >
                          <validation-provider #default="{ errors }" rules="required" >
                            <b-form-radio-group
                              v-model="infos.tableau3[0].motifExcursion"
                              :options="optionsMotifExcursion"
                              class="demo-inline-spacing mb-1 ml-1"
                              value-field="value"
                              text-field="text"
                              disabled-field="disabled"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[1].motifExcursion"
                            :options="optionsMotifExcursion"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[2].motifExcursion"
                            :options="optionsMotifExcursion"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[3].motifExcursion"
                            :options="optionsMotifExcursion"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[4].motifExcursion"
                            :options="optionsMotifExcursion"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[5].motifExcursion"
                            :options="optionsMotifExcursion"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>

                    </tr>
                    <!-- destination principale -->
                    <tr>
                      <th scope="row">303</th>
                      <td>Quelle a été la destination principale ?</td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].destinationPrincipale"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].destinationPrincipale"
                          type="text"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].destinationPrincipale"
                          type="text"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].destinationPrincipale"
                          type="text"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].destinationPrincipale"
                          type="text"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].destinationPrincipale"
                          type="text"
                        />
                      </td>
                    </tr>
                    <!-- distance km -->
                    <tr>
                      <th scope="row">304</th>
                      <td>Quelle a été la distance ? (Km)</td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].distanceKm"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].distanceKm"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].distanceKm"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].distanceKm"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].distanceKm"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].distanceKm"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <!-- type transport -->
                    <tr>
                      <th scope="row">305</th>
                      <td>
                        Type de transport
                      </td>
                      <td>
                        <b-row class="" >
                          <validation-provider #default="{ errors }" rules="required" >
                            <b-form-radio-group
                              v-model="infos.tableau3[0].typeTransport"
                              :options="optionsTypeTransport"
                              class="demo-inline-spacing mb-1 ml-1"
                              value-field="value"
                              text-field="text"
                              disabled-field="disabled"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[1].typeTransport"
                            :options="optionsTypeTransport"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[2].typeTransport"
                            :options="optionsTypeTransport"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[3].typeTransport"
                            :options="optionsTypeTransport"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[4].typeTransport"
                            :options="optionsTypeTransport"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[5].typeTransport"
                            :options="optionsTypeTransport"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>

                    </tr>
                    <!-- forfait touristique -->
                    <tr>
                      <th scope="row">308</th>
                      <td>S’agissait-il d’un forfait touristique tout compris ?</td>
                      <td>
                        <b-row class="" >
                          <validation-provider #default="{ errors }" rules="required" >
                            <b-form-radio-group
                              v-model="infos.tableau3[0].forfaitTouristique"
                              :options="optionsOuiNon"
                              class="demo-inline-spacing mb-1 ml-1"
                              value-field="value"
                              text-field="text"
                              disabled-field="disabled"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[1].forfaitTouristique"
                            :options="optionsOuiNon"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[2].forfaitTouristique"
                            :options="optionsOuiNon"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[3].forfaitTouristique"
                            :options="optionsOuiNon"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[4].forfaitTouristique"
                            :options="optionsOuiNon"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[5].forfaitTouristique"
                            :options="optionsOuiNon"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>

                    </tr>
                    <!-- montant du forfait -->
                    <tr>
                      <th scope="row">309</th>
                      <td>Montant total payé pour le forfait touristique ?</td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[0].montantForfait"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].montantForfait"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].montantForfait"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].montantForfait"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].montantForfait"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].montantForfait"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <!-- cout total du excursion -->
                    <tr>
                      <th scope="row">310</th>
                      <td>Cout total du Excursion ?</td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].coutTotalExcursion"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].coutTotalExcursion"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].coutTotalExcursion"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].coutTotalExcursion"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].coutTotalExcursion"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].coutTotalExcursion"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <!-- poste de dépenses -->
                    <tr>
                      <th scope="row" rowspan="8">311</th>
                      <td>1.	Hébergement </td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].posteDepenseHebergement"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].posteDepenseHebergement"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].posteDepenseHebergement"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].posteDepenseHebergement"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].posteDepenseHebergement"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].posteDepenseHebergement"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>2.	Repas et boissons </td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].posteDepenseRepasBoissons"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].posteDepenseRepasBoissons"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].posteDepenseRepasBoissons"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].posteDepenseRepasBoissons"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].posteDepenseRepasBoissons"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].posteDepenseRepasBoissons"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3.	Achats liés au Excursion touristique (nécessaires de Excursion, cadeaux, souvenirs, etc.) </td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].posteDepenseAchatsExcursion"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].posteDepenseAchatsExcursion"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].posteDepenseAchatsExcursion"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].posteDepenseAchatsExcursion"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].posteDepenseAchatsExcursion"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].posteDepenseAchatsExcursion"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>4.	Activités culturelles </td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].posteDepenseActivitesCulturelles"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].posteDepenseActivitesCulturelles"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].posteDepenseActivitesCulturelles"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].posteDepenseActivitesCulturelles"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].posteDepenseActivitesCulturelles"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].posteDepenseActivitesCulturelles"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>5.	Activités sportives</td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].posteDepenseActivitesSportives"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].posteDepenseActivitesSportives"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].posteDepenseActivitesSportives"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].posteDepenseActivitesSportives"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].posteDepenseActivitesSportives"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].posteDepenseActivitesSportives"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>6.	Dépenses médicales </td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].posteDepenseDepensesMedicales"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].posteDepenseDepensesMedicales"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].posteDepenseDepensesMedicales"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].posteDepenseDepensesMedicales"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].posteDepenseDepensesMedicales"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].posteDepenseDepensesMedicales"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>7.	Education/Formation </td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].posteDepenseEducationFormation"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].posteDepenseEducationFormation"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].posteDepenseEducationFormation"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].posteDepenseEducationFormation"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].posteDepenseEducationFormation"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].posteDepenseEducationFormation"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>8.	Autres </td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].posteDepenseAutres"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].posteDepenseAutres"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].posteDepenseAutres"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].posteDepenseAutres"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].posteDepenseAutres"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].posteDepenseAutres"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <!-- nbre de visites -->
                    <tr>
                      <th scope="row">312</th>
                      <td> Combien de fois avez-vous voyagé vers cette destination? </td>
                      <td>
                        <validation-provider #default="{ errors }" rules="required" >
                          <b-form-input
                            v-model.number="infos.tableau3[0].nombreExcursionsVersDestination"
                            type="number" min="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[1].nombreExcursionsVersDestination"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[2].nombreExcursionsVersDestination"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[3].nombreExcursionsVersDestination"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[4].nombreExcursionsVersDestination"
                          type="number" min="0"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model.number="infos.tableau3[5].nombreExcursionsVersDestination"
                          type="number" min="0"
                        />
                      </td>
                    </tr>
                    <!-- activite réalisee -->
                    <tr>
                      <th scope="row">313</th>
                      <td>Quelles activités avez-vous réalisés ?</td>
                      <td>
                        <b-row class="" >
                          <validation-provider #default="{ errors }" rules="required" >
                            <b-form-radio-group
                              v-model="infos.tableau3[0].activitesRealisees"
                              :options="optionsActivites"
                              class="demo-inline-spacing mb-1 ml-1"
                              value-field="value"
                              text-field="text"
                              disabled-field="disabled"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[1].activitesRealisees"
                            :options="optionsActivites"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[2].activitesRealisees"
                            :options="optionsActivites"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[3].activitesRealisees"
                            :options="optionsActivites"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[4].activitesRealisees"
                            :options="optionsActivites"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[5].activitesRealisees"
                            :options="optionsActivites"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                    </tr>
                    <!-- satisfactions -->
                    <tr>
                      <th scope="row">314</th>
                      <td>Quelles activités avez-vous réalisés ?</td>
                      <td>
                        <b-row class="" >
                          <validation-provider #default="{ errors }" rules="required" >
                            <b-form-radio-group
                              v-model="infos.tableau3[0].satisfactionExcursion"
                              :options="optionsSatisfaction"
                              class="demo-inline-spacing mb-1 ml-1"
                              value-field="value"
                              text-field="text"
                              disabled-field="disabled"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[1].satisfactionExcursion"
                            :options="optionsSatisfaction"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[2].satisfactionExcursion"
                            :options="optionsSatisfaction"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[3].satisfactionExcursion"
                            :options="optionsSatisfaction"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[4].satisfactionExcursion"
                            :options="optionsSatisfaction"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                      <td>
                        <b-row class="" >
                          <b-form-radio-group
                            v-model="infos.tableau3[5].satisfactionExcursion"
                            :options="optionsSatisfaction"
                            class="demo-inline-spacing mb-1 ml-1"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                        </b-row>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>

import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      email,
      required,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      optionsOuiNon: ["Oui", "Non"],
      optionsNonExcursion: [
        "Pas intéressé", 
        "Tarifs élevé de l'hébergement", 
        "Tarifs élevé des transports en commun",
        "Insuffisance de transports en commun",
        "Autres",
      ],
      optionsMotifExcursion: [
        "Visite de parents d’amis", 
        "Funérailles", 
        "Vacances, loisirs et détente",
        "Éducation et formation",
        "Santé et soins médicaux",
        "Religion/pèlerinage",
        "Manifestation culturelle ou cultuelle",
        "Achat",
        "Transit",
        "Professionnel",
        "Excursion d’affaire individuel (par exemple, visite d’un fournisseur ou d’un client)",
        "Conférence/congrès/séminaire",
        "Autres",
      ],
      optionsTypeTransport: [
        "Voiture privée", 
        "Voiture de location", 
        "Voiture de service",
        "Taxi",
        "Transport en commun",
        "Avion",
        "Barque/pirogue",
        "Motocyclette",
        "Autres",
      ],
      optionsLieuResidence: [
        "Hôtel et assimilés", 
        "Appartement/Hôtel", 
        "Parents/amis",
        "Résidence secondaire",
        "Maison/appartement meublé",
        "Chambre d'hôte",
        "Autres",
      ],
      optionsActivites: [
        "Restaurant/bars/cafés/snack-bars", 
        "Achats", 
        "Concerts/festival de musique",
        "Monuments",
        "Manifestation culturelle/cultuelle",
        "Randonnée/trekking en forêt/montagne",
        "Visite des parcs nationaux",
        "Golf",
        "Chasse",
        "Manifestation sportive",
        "Théâtre",
        "Evènement familial (mariage, funérailles, baptême, première communion, etc.)",
        "Musées",
        "Zoo/parcs d’attraction",
        "Plage",
        "Formation/conférence/séminaire",
        "Visite de sociétés",
        "Autres",
      ],
      optionsSatisfaction: [
        "Très mécontent", 
        "Mécontent", 
        "Ni mécontent ni content",
        "Content",
        "Très Content",
      ],

      infos: {
        // tableau3: [
        //   { 
        //     nombrePersonne: null,
        //     motifExcursion: null,
        //     destinationPrincipale: null,
        //     distanceKm: null,
        //     typeTransport: null,
        //     forfaitTouristique: null,
        //     montantForfait: null,
        //     coutTotalExcursion: null,
        //     posteDepenseRepasBoissons: null,
        //     posteDepenseAchatsMagasins: null,
        //     posteDepenseAchatsExcursion: null,
        //     posteDepenseActivitesCulturelles: null,
        //     posteDepenseActivitesSportives: null,
        //     posteDepenseDepensesMedicales: null,
        //     posteDepenseEducationFormation: null,
        //     posteDepenseAutres: null,
        //     nombreExcursionsVersDestination: null,
        //     activitesRealisees: null,
        //     satisfactionExcursion: null,
        //   },
        //   { 
        //     nombrePersonne: null,
        //     motifExcursion: null,
        //     destinationPrincipale: null,
        //     distanceKm: null,
        //     typeTransport: null,
        //     forfaitTouristique: null,
        //     montantForfait: null,
        //     coutTotalExcursion: null,
        //     posteDepenseRepasBoissons: null,
        //     posteDepenseAchatsMagasins: null,
        //     posteDepenseAchatsExcursion: null,
        //     posteDepenseActivitesCulturelles: null,
        //     posteDepenseActivitesSportives: null,
        //     posteDepenseDepensesMedicales: null,
        //     posteDepenseEducationFormation: null,
        //     posteDepenseAutres: null,
        //     nombreExcursionsVersDestination: null,
        //     activitesRealisees: null,
        //     satisfactionExcursion: null,
        //   },
        //   { 
        //     nombrePersonne: null,
        //     motifExcursion: null,
        //     destinationPrincipale: null,
        //     distanceKm: null,
        //     typeTransport: null,
        //     forfaitTouristique: null,
        //     montantForfait: null,
        //     coutTotalExcursion: null,
        //     posteDepenseRepasBoissons: null,
        //     posteDepenseAchatsMagasins: null,
        //     posteDepenseAchatsExcursion: null,
        //     posteDepenseActivitesCulturelles: null,
        //     posteDepenseActivitesSportives: null,
        //     posteDepenseDepensesMedicales: null,
        //     posteDepenseEducationFormation: null,
        //     posteDepenseAutres: null,
        //     nombreExcursionsVersDestination: null,
        //     activitesRealisees: null,
        //     satisfactionExcursion: null,
        //   },
        //   { 
        //     nombrePersonne: null,
        //     motifExcursion: null,
        //     destinationPrincipale: null,
        //     distanceKm: null,
        //     typeTransport: null,
        //     forfaitTouristique: null,
        //     montantForfait: null,
        //     coutTotalExcursion: null,
        //     posteDepenseRepasBoissons: null,
        //     posteDepenseAchatsMagasins: null,
        //     posteDepenseAchatsExcursion: null,
        //     posteDepenseActivitesCulturelles: null,
        //     posteDepenseActivitesSportives: null,
        //     posteDepenseDepensesMedicales: null,
        //     posteDepenseEducationFormation: null,
        //     posteDepenseAutres: null,
        //     nombreExcursionsVersDestination: null,
        //     activitesRealisees: null,
        //     satisfactionExcursion: null,
        //   },
        //   { 
        //     nombrePersonne: null,
        //     motifExcursion: null,
        //     destinationPrincipale: null,
        //     distanceKm: null,
        //     typeTransport: null,
        //     forfaitTouristique: null,
        //     montantForfait: null,
        //     coutTotalExcursion: null,
        //     posteDepenseRepasBoissons: null,
        //     posteDepenseAchatsMagasins: null,
        //     posteDepenseAchatsExcursion: null,
        //     posteDepenseActivitesCulturelles: null,
        //     posteDepenseActivitesSportives: null,
        //     posteDepenseDepensesMedicales: null,
        //     posteDepenseEducationFormation: null,
        //     posteDepenseAutres: null,
        //     nombreExcursionsVersDestination: null,
        //     activitesRealisees: null,
        //     satisfactionExcursion: null,
        //   },
        //   { 
        //     nombrePersonne: null,
        //     motifExcursion: null,
        //     destinationPrincipale: null,
        //     distanceKm: null,
        //     typeTransport: null,
        //     forfaitTouristique: null,
        //     montantForfait: null,
        //     coutTotalExcursion: null,
        //     posteDepenseRepasBoissons: null,
        //     posteDepenseAchatsMagasins: null,
        //     posteDepenseAchatsExcursion: null,
        //     posteDepenseActivitesCulturelles: null,
        //     posteDepenseActivitesSportives: null,
        //     posteDepenseDepensesMedicales: null,
        //     posteDepenseEducationFormation: null,
        //     posteDepenseAutres: null,
        //     nombreExcursionsVersDestination: null,
        //     activitesRealisees: null,
        //     satisfactionExcursion: null,
        //   },
        // ],
      },
    }
  },
  computed: {
    // ...mapState('params', {
    //   listPays: 'listPays',
    // }),
  },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.inputData) {
      this.infos = this.inputData
    } else if (this.$route.params.data) {
      this.infos = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },
  methods: {
    // ...mapActions('params', {
    //   fetchAnnees: 'fetchAnnees',
    // }),
    onCancel() {
      this.$emit('onCancel', {})
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          if (this.inputData) {
            this.$emit('onFormSuccess', this.infos)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.infos },
            })
          }
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
